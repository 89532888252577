<template>
  <div class="container">
    <div class="container-title">
      <div class="title-left">
        <span class="title-text">审核并完善患者信息</span>
      </div>
      <div class="title-right">
        <el-button @click="handleBack">返回</el-button>
        <el-button type="danger" plain @click="handleNext(0)">驳回</el-button>
        <el-button type="primary" @click="handleNext(1)">完成审核</el-button>
      </div>
    </div>
    <div class="container-content">
        <div class="content-item">
          <p class="item-title">1、患者信息</p>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="80px"
              class="demo-ruleForm">
              <div>
                <el-form-item label="患者姓名" prop="name">
                  <el-input
                    size="small"
                    v-model="ruleForm.name"
                    minlength="2"
                    maxlength="20">
                  </el-input>
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                  <el-select v-model="ruleForm.gender" placeholder="请选择性别">
                    <el-option
                      v-for="item in regionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="病历号" prop="recordNo">
                  <el-input
                    size="small"
                    v-model="ruleForm.recordNo"
                    placeholder="请输入病历号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="certificateNo">
                  <el-input
                    size="small"
                    :disabled="true"
                    v-model="ruleForm.certificateNo"
                    autocomplete="off"
                    maxlength="18"
                  ></el-input>
                </el-form-item>
                <el-form-item class="hasDiseases" label="基础疾病">
                  <el-radio-group v-model="hasUnderlyingDiseases" @change="changeHasDiseases">
                    <el-radio :label="0">无</el-radio>
                    <el-radio :label="1">有</el-radio>
                  </el-radio-group>
                  <div class="diseases-box" v-if="hasUnderlyingDiseases === 1">
                    <el-checkbox-group
                      v-model="underlyingDiseases"
                      @change="handleChangeUdrDis">
                      <el-checkbox
                        class="el-checkbox-width"
                        v-for="item in ruleForm.underlyingDiseaseSelect" :label="item.code"
                        :key="item.code">
                        {{item.text}}
                        <el-input
                          size="small"
                          class="input-otherdiseases"
                          v-show="item.type === 2 && visableOtherDisInput"
                          v-model="ruleForm.otherDiseases"
                          minlength="2"
                          maxlength="20"
                        ></el-input>
                        <br>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </div>
            </el-form>
        </div>
        <div class="content-item">
          <p class="item-title">2、CIED植入信息</p>
            <el-form :model="implantInfo" :rules="implantInfoRules" ref="implantInfoRuleForm" label-width="80px">
              <el-row>
                  <el-form-item label="植入日期" prop="implantDate">
                    <el-date-picker
                      v-model="implantInfo.implantDate"
                      class="fix-form__width"
                      type="date"
                      format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                      value-format="timestamp"
                      placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="植入医院">
                    <el-input size="small" v-model="implantInfo.implantHospital" class="fix-form__width"></el-input>
                  </el-form-item>
                </el-row>
              <el-row>
                <el-form-item label="术者" prop="implantDoctor">
                  <el-input  size="small" v-model="implantInfo.implantDoctor" minlength="2" maxlength="20" class="fix-form__width"></el-input>
                </el-form-item>
              </el-row>
            </el-form>
        </div>
        <div class="content-item">
          <p class="item-title">3、CIED植入部位</p>
          <el-radio-group
          class="radio-group-one-level"
          v-model="implantPosition"
          @change="onChangeImplantPosition">
            <el-radio :label="1">左侧</el-radio>
            <el-radio :label="2">右侧</el-radio>
            <el-radio :label="3">
            其他
            <el-input
              size="small"
              :disabled="implantPosition !== 3"
              v-model="otherImplantSite"
              placeholder="请输入内容"
              style="width: 228px; margin-left: 4px;">
            </el-input>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="content-item">
          <p class="item-title">4、适应症</p>
          <el-checkbox-group
            v-model="indications.indicationsSymptom" @change="changeAdaptation">
            <el-checkbox class="el-check-pinpai" :label="0">
              心动过缓
            </el-checkbox>
            <el-radio-group v-if="indications.indicationsSymptom.indexOf(0) !== -1" v-model="indications.bradycardiaRadio" class="adaptation_content--1">
              <el-radio :label="0">病态窦房结综合征</el-radio>
              <el-radio :label="1">房室传导阻滞</el-radio>
              <el-radio :label="2">房颤伴慢室率</el-radio>
            </el-radio-group>
            <el-checkbox class="el-check-pinpai" :label="1">
              心力衰竭
            </el-checkbox>
            <el-checkbox class="el-check-pinpai" :label="2">
              室性心律失常
            </el-checkbox>
            <el-radio-group @change="changeIndicationsSymptop" v-if="indications.indicationsSymptom.indexOf(2) !== -1" v-model="indications.arrhythmiaRadio" class="adaptation_content--1">
              <el-radio :label="0">室性心动过速</el-radio>
              <el-radio :label="1">心室颤动</el-radio>
              <el-radio :label="2">室性期前收缩</el-radio>
            </el-radio-group>
            <el-checkbox :label="3">
              其他
            </el-checkbox>
            <el-input
              size="small"
              :disabled="indications.indicationsSymptom.indexOf(3) === -1"
              v-model="indications.otherIndications"
              placeholder="请输入内容"
              style="width: 228px; margin-left: 4px;"
              minlength="2"
              maxlength="20"
              :onkeyup="
                (function () {
                  indications.otherIndications=indications.otherIndications.replace(/[^\u4e00-\u9fa5a-zA-Z0-9·]/ig,'')
                })()">
            </el-input>
          </el-checkbox-group>
        </div>
        <div class="content-item">
          <p class="item-title">5、CIED类型</p>
          <el-radio-group
          v-model="ciedType.ciedTypeRadio"
          @change="changeCiedType">
            <el-radio :label="0">单腔起搏器</el-radio>
            <el-radio :label="1">双腔起搏器</el-radio>
            <el-radio :label="2">单腔ICD</el-radio>
            <el-radio :label="3">双腔ICD</el-radio>
            <el-radio :label="4">CRT-P</el-radio>
            <el-radio :label="5">CRT-D</el-radio>
            <el-radio :label="6">
              其他
              <el-input
                size="small"
                :disabled="ciedType.ciedTypeRadio !== 6"
                v-model="ciedType.otherCiedType"
                placeholder="请输入内容"
                style="display: inline-block; width: 228px"
                :onkeyup="
                (function () {
                  ciedType.otherCiedType=ciedType.otherCiedType.replace(rules,'')
                })()"
              ></el-input>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="content-item">
          <p class="item-title">6、CIED品牌</p>
          <el-radio-group
            class="ciedBrand-radio-group"
            v-model="ciedBrand"
            @change="newChangeCiedBrand">
            <el-radio v-for="(i, k) in newBrandList" :key="k" :label="i.code">{{i.name}}</el-radio>
            <el-input v-if="ciedBrand === '5'" size="small" style="width: 200px; margin-right: 15px" v-model="otherCiedBrand" placeholder="其他品牌"></el-input>
          </el-radio-group>
        </div>
        <div class="content-item">
          <p class="item-title">7、CIED型号</p>
          <el-select size="small" v-if="ciedBrand && (ciedBrand !== '5')" filterable style="margin-right: 15px;" class="el-checkbox-input" allow-create v-model="ciedModel" :placeholder="brandPlaceholder">
            <el-option
              v-for="(m, c) in newModeList"
              :key="c"
              :label="m.name"
              :value="m.code"
              >
            </el-option>
            <el-option label="其他" value="0"></el-option>
          </el-select>
          <el-input v-if="ciedModel === '0' || ciedBrand === '5'" size="small" v-model="otherCiedModel" placeholder="请输入其他型号" ></el-input>
        </div>
        <div class="content-item">
          <p class="item-title">8、CIED序列号</p>
          <el-input v-if="ciedBrand" size="small" v-model="ciedSn" placeholder="请填写CIED序列号"></el-input>
        </div>
        <div class="content-item">
          <p class="item-title">9、导线类型及参数</p>
          <el-form
          :model="wireParam">
            <el-form-item>
              <el-checkbox
              v-model="wireParam.checkedAtrial"
              label="心房导线"
              @change="changeLead">
              </el-checkbox>
              <div class="lead-atrial" v-if="wireParam.checkedAtrial">
                <div class="atrial-title">
                  植入部位
                </div>
                <div class="atrial-content">
                  <el-radio-group  v-model="wireParam.atrialImplantPosition">
                    <el-radio :label="0">右心耳</el-radio>
                    <el-radio :label="1">右心房侧壁</el-radio>
                  </el-radio-group>
                </div>
                <br>
                <div class="atrial-title">导线型号</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入导线型号" v-model="wireParam.atrialWireModel"></el-input>
                </div>
                <br>
                <div class="atrial-title">
                  起搏阈值
                </div>
                <div class="atrial-content">
                  <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdV">
                  <i style="margin-right: 10px;" slot="suffix">V@</i></el-input>
                  <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdMs">
                  <i style="margin-right: 10px;" slot="suffix">ms</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">
                  P波高度
                </div>
                <div class="atrial-content">
                  <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.atrialPWaveAltitude">
                  <i style="margin-right: 10px;" slot="suffix">mV</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">
                  阻抗
                </div>
                <div class="atrial-content">
                  <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.atrialImpedance">
                  <i style="margin-right: 10px;" slot="suffix">Ω</i>
                  </el-input>
                </div>
              </div>
              <el-checkbox v-model="wireParam.checkedRightVentricular" class="el-check-pinpai" label="右心室导线" @change="changeLead"></el-checkbox>
              <div class="lead-right" v-if="wireParam.checkedRightVentricular">
              <div class="atrial-title">植入部位</div>
              <div class="atrial-content">
                <el-radio-group v-model="wireParam.rightImplantPosition">
                  <el-radio :label="0">右室心尖部</el-radio>
                  <el-radio :label="1">右室间隔部</el-radio>
                  <el-radio :label="2">希氏束区域</el-radio>
                  <el-radio :label="3">左束支区域</el-radio>
                </el-radio-group>
              </div>
              <br>
              <div class="atrial-title">导线型号</div>
              <div class="atrial-content">
                <el-input class="atrial-input-one" size="small" placeholder="请输入导线型号" v-model="wireParam.rightWireModel"></el-input>
              </div>
              <br>
              <div class="atrial-title">起搏阈值</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdV">
                <i style="margin-right: 10px;" slot="suffix">V@</i>
                </el-input>
                <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdMs">
                <i style="margin-right: 10px;" slot="suffix">ms</i>
                </el-input>
              </div>
              <br>
              <div class="atrial-title">R波高度</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.rightRWaveAltitude">
                <i style="margin-right: 10px;" slot="suffix">mV</i>
                </el-input>
              </div>
              <br>
              <div class="atrial-title">阻抗</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.rightImpedance">
                <i style="margin-right: 10px;" slot="suffix">Ω</i>
                </el-input>
              </div>
              <br>
              <div class="atrial-title-long">高压除颤阻抗</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input-one-long" placeholder="请输入内容" v-model="wireParam.rightHighVoltageImpedance">
                <i style="margin-right: 10px;" slot="suffix">Ω</i>
                </el-input>
              </div>
              </div>
              <el-checkbox v-model="wireParam.checkedLeftVentricular" class="el-check-pinpai" label="左心室导线" @change="changeLead"></el-checkbox>
              <div class="lead-left" v-if="wireParam.checkedLeftVentricular">
                <div class="atrial-title">植入部位</div>
                <div class="atrial-content">
                  <el-radio-group v-model="wireParam.leftImplantPosition" @change="changeLeadSite">
                    <el-radio :label="0">侧后静脉</el-radio>
                    <el-radio :label="1">侧静脉</el-radio>
                    <el-radio :label="2">其他</el-radio>
                    <el-input
                      size="mini"
                      placeholder="请输入内容"
                      v-show="wireParam.leftImplantPosition === 2"
                      v-model="wireParam.leftOtherPosition"
                      minlength="2"
                      maxlength="20">
                    </el-input>
                  </el-radio-group>
                </div>
                <br>
                <div class="atrial-title">导线型号</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入导线型号" v-model="wireParam.leftWireModel"></el-input>
                </div>
                <br>
                <div class="atrial-title">起搏阈值</div>
                <div class="atrial-content">
                  <el-input
                  size="small"
                  class="atrial-input"
                  placeholder="请输入内容"
                  v-model="wireParam.leftPacingThresholdV"
                  >
                  <i style="margin-right: 10px;" slot="suffix">V@</i>
                  </el-input>
                  <el-input
                  size="small"
                  class="atrial-input"
                  placeholder="请输入内容"
                  v-model="wireParam.leftPacingThresholdMs">
                  <i style="margin-right: 10px;" slot="suffix">ms</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">R波高度</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入内容" v-model="wireParam.leftRWaveAltitude">
                  <i style="margin-right: 10px;" slot="suffix">mV</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">阻抗</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入内容" v-model="wireParam.leftImpedance">
                  <i style="margin-right: 10px;" slot="suffix">Ω</i>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="content-item">
          <p class="item-title">10、起搏器参数设置</p>
          <div>起搏模式</div>
          <el-radio-group v-model="pacemakerQuery.pacemakerModel" class="subPart adaptation_content--1">
            <el-radio :label="1">AAI(R)</el-radio>
            <el-radio :label="2">VVI(R)</el-radio>
            <el-radio :label="3">DDD(R)</el-radio>
          </el-radio-group>
          <div>基线频率</div>
          <div>
            <el-input
            size="small"
            maxlength="2"
            v-model="pacemakerQuery.bareLineHeartRate"
            class="atrial-input-one"
            placeholder="请输入内容"
            :onkeyup="
              (function () {
                pacemakerQuery.bareLineHeartRate=pacemakerQuery.bareLineHeartRate.replace(/[^\d]/g,'')
              })()"
            >
            <i
            style="margin-right: 10px; line-height: 32px;" slot="suffix">bpm</i>
            </el-input>
          </div>
          <span >
          </span>
        </div>
        <div class="content-item">
          <p class="item-title">11、围术期不良事件</p>
          <el-radio-group
            class="radio-group-one-level"
            v-model="adverseEvent.hasEvent"
            @change="changeEvent">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
          </el-radio-group>
          <el-checkbox-group
            v-if="adverseEvent.hasEvent === 1"
            v-model="adverseEvent.events"
            class="adaptation_content--1 events"
            @change="changeEventCheckBox">
            <el-checkbox :label="1" name="type">气胸</el-checkbox>
            <el-checkbox :label="2" name="type">血胸</el-checkbox>
            <el-checkbox :label="3" name="type">血气胸</el-checkbox>
            <el-checkbox :label="4" name="type">导线穿孔</el-checkbox>
            <el-checkbox :label="5" name="type">导线脱落</el-checkbox>
            <el-checkbox :label="6" name="type">感染</el-checkbox>
            <el-checkbox :label="7" name="type">导线重置</el-checkbox>
            <el-checkbox :label="8" name="type">血肿</el-checkbox>
            <el-checkbox :label="9" name="type">其他</el-checkbox>
            <el-input
              size="small"
              v-if="adverseEvent.events.indexOf(9) !== -1"
              v-model="adverseEvent.otherEvent"
              style="width: 228px; margin-left: 4px"
              placeholder="">
              </el-input>
          </el-checkbox-group>
        </div>
        <div style="height: 40px;"></div>
      </div>
    <el-dialog
      title="驳回原因"
      :visible.sync="rejectionReasonVisible"
      width="50%">
      <el-input
        type="textarea"
        placeholder="请输入驳回原因"
        v-model="rejectCause"
        maxlength="100"
        show-word-limit
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancelChangeRejectCause">取 消</el-button>
        <el-button type="primary" @click="onSaveChangeRejectCause">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBaselineInfo,
  getDictionary
} from '@/service/module/followup'
import { confirmPatientReview, rejectPatientReview } from '@/service/module/task'
export default ({
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      regionList: [ // 性别列表
        {
          value: '1',
          label: '男'
        }, {
          value: '2',
          label: '女'
        }
      ],
      ruleForm: {},
      implantInfoRuleForm: {}, // CIED植入信息表单
      rejectCause: '', // 驳回原因
      rejectionReasonVisible: false, // 驳回原因弹窗是否显示,

      // 1. 患者信息
      hasUnderlyingDiseases: -1, // 是否有基础疾病
      underlyingDiseases: [], // 基础疾病列表
      visableOtherDisInput: false, // 基础疾病-选中其他-出现输入框
      rules: { // 表单校验
        name: [
          { required: true, message: '请输入患者姓名', trigger: 'blur' }
          // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          // { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        medicalRecordNumber: [
          { required: true, message: '请输入病历号', trigger: 'blur' },
          { min: 6, max: 7, message: '长度在 6-7 个字符', trigger: 'blur' },
          { pattern: /^\d+$/, message: '请输入数字' }
        ],
        certificateNo: [
          { required: true, trigger: 'blur' }
        ]
      },

      // 2. CIED植入信息
      implantInfoRules: { // 植入信息表单校验
        implantDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        implantDoctor: [
          { required: true, message: '请输入术者姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ]
      },
      implantInfo: { // 植入信息
        implantDate: '',
        implantDoctor: '',
        implantHospital: ''
      },

      // 3. 植入部位
      implantPosition: -1,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      otherImplantSite: '', // 其他内容

      // 4. 适应证
      indications: {
        indicationsSymptom: [],
        bradycardiaRadio: null,
        otherIndications: '',
        arrhythmiaRadio: null
      },

      // 5. CIED类型
      ciedType: {
        ciedTypeRadio: -1,
        otherCiedType: ''
      },

      // 6、品牌
      newBrandList: [],
      newModeList: [],
      ciedBrand: '',
      ciedModel: '',
      otherCiedBrand: '',
      otherCiedModel: '',

      // 品牌序列号
      ciedSn: '',
      // 7、导线
      wireParam: {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        // 心房导线参数
        atrialImplantPosition: -1,
        atrialWireModel: '',
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPWaveAltitude: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightImplantPosition: -1,
        rightWireModel: '',
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightRWaveAltitude: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftOtherPosition: '',
        leftImplantPosition: -1,
        leftWireModel: '',
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftRWaveAltitude: '',
        leftImpedance: ''
      },

      // 8、起搏器参数
      pacemakerQuery: {
        checkedPacemakerModel: false,
        bareLineHeartRate: '',
        checkedBareLineHeartRate: false,
        pacemakerModel: 0
      },

      // 9、不良事件
      adverseEvent: {
        hasEvent: -1,
        events: [],
        otherEvent: ''
      },
      patientId: Number(this.$route.query.id)
    }
  },
  created () {
    this.getBaselineInfo()
    this.getBrandData()
  },
  mounted () {
    this._send('$pageView', '审核并完善患者信息进入情况', '审核并完善患者信息页面 加载成功时触发', {
      accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
      doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
      hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
    })
  },
  computed: {
    brandPlaceholder () {
      let placeholder = ''
      switch (this.ciedBrand) {
        case '1': placeholder = '请选择美敦力型号'
          break
        case '2': placeholder = '请选择雅培型号'
          break
        case '3': placeholder = '请选择百多力型号'
          break
        case '4': placeholder = '请选择波科型号'
          break
        default: placeholder = ''
          break
      }
      return placeholder
    }
  },
  methods: {
    async getBaselineInfo () {
      try {
        const res = await getBaselineInfo(this.patientId)
        const resDictionary = await getDictionary('underlyingDisease')
        this.ruleForm = res.data
        this.ruleForm.underlyingDiseaseSelect = resDictionary.data
        if (res.data.underlyingDiseases.length > 0) {
          this.hasUnderlyingDiseases = 1
          this.underlyingDiseases = res.data.underlyingDiseases
          this.ruleForm.otherDiseases = res.data.otherDiseases
          for (let i = 0; i < this.underlyingDiseases.length; i++) {
            this.underlyingDiseases[i] = +this.underlyingDiseases[i]
          }
          if (res.data.underlyingDiseases.indexOf(4) && res.data.otherDiseases) {
            this.visableOtherDisInput = true
          }
        }

        if (!res.data.implantDate) {
          this.ruleForm.implantDate = ''
        }
        if (!res.data.otherDiseases) {
          this.$set(this.ruleForm, 'otherDiseases', '')
        }
        console.log(this.ruleForm)
        if (res.data) {
          this.dialogVisible = true
        }
      } catch (e) {
        console.log('getAwaitFollowUserInfo-err:', e)
      }
    },
    async getBrandData () {
      try {
        const res = await getDictionary('ciedBrand')
        this.newBrandList = res.data
        console.log('品牌:', res)
      } catch (e) {
        console.log(e)
      }
    },
    changeHasDiseases (val) {
      console.log('22222:', this.hasUnderlyingDiseases)
      if (val !== 1) {
        this.underlyingDiseases = []
        this.visableOtherDisInput = false
        this.ruleForm.otherDiseases = ''
        console.log(this.ruleForm)
      }
    },
    handleChangeUdrDis (list) {
      console.log(list)
      this.visableOtherDisInput = false
      if (list.indexOf(4) !== -1) {
        this.visableOtherDisInput = true
      } else {
        this.visableOtherDisInput = false
        this.ruleForm.otherDiseases = ''
      }
    },
    changeAdaptation (val) {
      if (val.indexOf(0) === -1) {
        this.indications.bradycardiaRadio = null
      }
      if (val.indexOf(3) === -1) {
        this.indications.otherIndications = ''
      }
      if (val.indexOf(2) === -1) {
        this.indications.arrhythmiaRadio = null
      }
    },
    changeCiedType (val) {
      if (val !== 6) {
        this.ciedType.otherCiedType = ''
      }
    },
    newChangeCiedBrand (val) {
      this.ciedBrand = val
      if (this.ciedBrand !== '5') {
        const curOpt = this.newBrandList.find(i => i.code === val)
        console.log(curOpt)
        this.newModeList = curOpt.models
        this.otherCiedBrand = ''
        this.ciedModel = ''
      } else {
        this.ciedModel = '0'
      }
      this.otherCiedModel = ''
    },
    changeLead () {
      if (!this.wireParam.checkedAtrial) {
        this.wireParam.atrialImplantPosition = -1
        this.wireParam.atrialWireModel = ''
        this.wireParam.atrialPacingThresholdV = ''
        this.wireParam.atrialPacingThresholdMs = ''
        this.wireParam.atrialPWaveAltitude = ''
        this.wireParam.atrialImpedance = ''
      }
      if (!this.wireParam.checkedRightVentricular) {
        this.wireParam.rightImplantPosition = -1
        this.wireParam.rightWireModel = ''
        this.wireParam.rightPacingThresholdV = ''
        this.wireParam.rightPacingThresholdMs = ''
        this.wireParam.rightRWaveAltitude = ''
        this.wireParam.rightHighVoltageImpedance = ''
        this.wireParam.rightImpedance = ''
      }
      if (!this.wireParam.checkedLeftVentricular) {
        this.wireParam.leftOtherPosition = ''
        this.wireParam.leftImplantPosition = -1
        this.wireParam.leftWireModel = ''
        this.wireParam.leftPacingThresholdV = ''
        this.wireParam.leftPacingThresholdMs = ''
        this.wireParam.leftRWaveAltitude = ''
        this.wireParam.leftImpedance = ''
      }
    },
    changeLeadSite (val) {
      if (val !== 2) {
        this.wireParam.leftOtherPosition = ''
      }
    },
    changeEvent () {
      if (this.adverseEvent.hasEvent !== 1) {
        this.adverseEvent.events = []
        this.adverseEvent.otherEvent = ''
      }
    },
    changeEventCheckBox (val) {
      if (val.indexOf(9) === -1) {
        this.adverseEvent.otherEvent = ''
      }
    },
    changeIndicationsSymptop () {
      this.$forceUpdate()
    },
    onChangeImplantPosition (e) {
      if (e !== 3 && this.otherImplantSite) {
        this.otherImplantSite = ''
      }
    },
    handleBack () {
      this.$router.push({
        name: 'auditingPatient'
      })
    },
    async handleNext (type) {
      Object.assign(this.ruleForm, this.implantInfoRuleForm)

      this.ruleForm.patientId = this.patientId

      this.ruleForm.implantDate = this.implantInfo.implantDate
      this.ruleForm.operator = this.implantInfo.implantDoctor
      this.ruleForm.hospitalName = this.implantInfo.implantHospital

      // 序列号
      this.ruleForm.ciedBrand = this.ciedBrand
      this.ruleForm.ciedModel = this.ciedModel
      this.ruleForm.otherCiedBrand = this.otherCiedBrand
      this.ruleForm.otherCiedModel = this.otherCiedModel

      this.ruleForm.ciedSn = this.ciedSn

      this.ruleForm.ciedImplantSite = this.implantPosition
      this.ruleForm.adaptationDisease = this.indications
      this.ruleForm.ciedKind = this.ciedType
      this.ruleForm.leadParam = this.wireParam
      this.ruleForm.pacemakerParam = this.pacemakerQuery
      this.ruleForm.adverseEvent = this.adverseEvent

      console.log('验证提交参数:', this.ruleForm)

      if (type === 0) {
        this._send('$click', '驳回点击', '驳回按钮 发生点击行为触发', {
          accountName: JSON.parse(sessionStorage.getItem('user')).accountName,
          doctorName: JSON.parse(sessionStorage.getItem('user')).doctorName,
          hospitalName: JSON.parse(sessionStorage.getItem('user')).hospitalName
        })
        this.rejectCause = ''
        this.ruleForm.type = type
        this.rejectionReasonVisible = true
      } else {
        this.$refs.ruleForm.validate(async (valid) => {
          this.$refs.implantInfoRuleForm.validate(async (v) => {
            if (valid && v) {
              if (valid & v) {
                if (this.visableOtherDisInput && this.ruleForm.otherDiseases === undefined) {
                  this.$message.error('请输入其他疾病！')
                  return false
                }
                const ruleForm = this.ruleForm
                ruleForm.type = type
                const formAddress = ruleForm.formAddress
                if (formAddress && formAddress.length > 0) {
                  ruleForm.province = formAddress[0]
                  ruleForm.city = formAddress[1]
                  ruleForm.district = formAddress[2]
                }
                this.ruleForm.underlyingDiseases = this.underlyingDiseases
                this.confirmPatientReview(1)
              } else {
                console.log('error submit!!')
                return false
              }
            } else {
              console.log('error submit!!')
              return false
            }
          })
        })
      }
    },
    async confirmPatientReview (type) {
      try {
        if (type === 1) {
          // 审核通过
          // this.disableCheckBtn = true
          this.ruleForm.patientId = this.patientId
          const res = await confirmPatientReview(this.ruleForm)
          if (res.code === 200) {
            this.$message.success('保存成功')
            this.$router.push({
              name: 'auditingPatient'
            })
          } else {
            this.$message.error(res.message)
          }
        } else {
          // 审核驳回
          const res = await rejectPatientReview({
            patientId: this.patientId,
            rejectCause: this.rejectCause
          })
          if (res.code === 200) {
            this.$message.success('已驳回')
            this.rejectionReasonVisible = false
            this.$router.push({
              name: 'auditingPatient'
            })
          }
        }
      } catch (e) {
        this.$message.error('保存失败')
        console.log('confirmPatientReview-err:', e)
      }
    },
    onCancelChangeRejectCause () {
      this.rejectionReasonVisible = false
      this.rejectCause = ''
    },
    onSaveChangeRejectCause () {
      if (!this.rejectCause) {
        this.$message.error('请输入驳回原因')
        return
      }
      this.confirmPatientReview(0)
    }
  }
})
</script>

<style lang="scss" scoped>
.layout ,.container ,.container[data-v-13877386],.main[data-v-13877386] {
  padding-top: 0;
}
// test
.container {
  .container-title {
  width: 100%;
  height: 72px;
  box-shadow: inset 0px -1px 0px #DCDFE6;
  .title-left {
    float: left;
    margin-left: 24px;
    .title-text {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 72px;
      color: #131414;
      &::after {
        container: ' ';
        display: inline-block;
        width: 1px;
        height: 16px;
        background-color: #E7E8EA;
        margin-left: 12px;
        border-radius: 2px;
      }
    }
  }
  .title-right {
    float: right;
    margin: 16px 24px;
  }
  }
  .container-content {
  // width: 532px;
  margin-top: 4px;
    .content-item {
      margin: 24px 0 0 24px;
      .item-title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #131414;
        margin-bottom: 8px;
      }
    }
    .el-input, .el-select {
      width: 213px;
    }
    .el-radio {
      height: 40px;
      line-height: 40px;
      margin-bottom: 8px;
    }
    .diseases-box {
      width: 580px;
    }
    .el-form {
      color: #606266;
      .el-form-item {
        margin-bottom: 16px;
      }
    }
    .adaptation_content--1 {
      display: flex;
      align-items: center;
      ::v-deep .el-radio {
        margin-bottom: 0;
      }
    }
    .lead-atrial, .lead-right, .lead-left {
      width: 100%;
    }
    .events {
      // width: 600px;
      display: flex;
      flex-wrap: wrap;
    }
    .atrial-title,.atrial-title-long {
      display: inline-block;
      color: #606266;
      font-size: 14px;
      margin-right: 8px;
      width: 56px;
    }
    .atrial-title-long {
      width: 85px;
    }
    .atrial-content{
      display: inline-block;
    }
    .atrial-input-one-long {
      width: 209px;
    }
    .atrial-input {
      width: 115px;
      margin-right:8px;
    }
    .atrial-input-one {
      width: 238px;
    }
    .el-radio[data-v-083ba69c] {
      margin: 0 24px 0 0;
      color: #606266;
      font-weight: 400;
    }
    .ciedBrand-radio-group {
      .el-radio {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
